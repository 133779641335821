import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import {
  PublicationControllerService,
  PublicationVo,
} from '@/__generated__/FrontApi';
import BgMobile from '@/assets/img/bg_database_mobile@2x.png';
import Bg from '@/assets/img/tmp/what-we-do/database_bg.png';
import Button from '@/components/Button';
import DatabaseCard from '@/components/Card/DatabaseCard';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import NewsBoardSearchBar from '@/components/NewsBoardSearchBar';
import TagPagination from '@/components/Pagination/TagPagination';
import DatabaseItem from '@/components/Publication/DatabaseItem';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import {
  BOARD_KIND_PUBLICATION,
  COMMON_CODE_PUBLICATION,
} from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { getCurrentPageInfo, PageInfo } from '@/helpers/PaginationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps, navigate, Link } from 'gatsby';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const DatabaseContainer = styled.div`
  margin-top: 72px;
`;
const TagBox = styled.dl`
  margin-top: 32px;
  background: #f8f9fa;
  padding: 46px 32px 35px;

  ${Tit} {
    line-height: 1.6;
  }
  dt {
    padding-bottom: 24px;
  }
  ${Button} {
    margin-right: 12px;
    margin-bottom: 14px;
    font-weight: normal;
    background: #fff;
  }
  ${breakpoint(`mobile`)} {
    padding: 24px 20px;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 24px;
    ${Button} {
      font-size: 14px;
      height: 36px;
      line-height: 1;
      margin-right: 8px;
    }
  }
`;
const KeywordButton = styled(Button)`
  margin-right: 12px;
  margin-bottom: 14px;
  font-weight: normal;
  background: #fff;
`;

const SectionTop = styled(Section)`
  background: url(${Bg}) center no-repeat;
  max-width: 1920px;
  margin: 0 auto;
  background-size: cover;
  padding-bottom: 30px !important;
  ${SectionHeader} {
    padding-bottom: 34px;
  }
  ${breakpoint(`tablet`)} {
    padding-top: 48px !important;
    padding-bottom: 0 !important;
  }
  ${breakpoint(`mobile`)} {
    background: url(${BgMobile}) no-repeat center top;
    background-size: 100%;
  }
`;

const SectionList = styled(Section)`
  padding-top: 96px !important;

  ${breakpoint(`mobile`)} {
    padding-top: 0 !important;

    ${Row} {
      padding: 0;
      width: 100%;
    }
  }
`;

interface categoryProps {
  code: string;
  codeName: string;
}

const Database: React.FC<PageProps> = ({ location }) => {
  // 키워드 탭 강제 렌더링 실행용 state
  const [loaded, setLoaded] = useState(false);
  const params = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );
  const searchCurrentPage = params.currentPage ? Number(params.currentPage) : 1;

  const [categoryList, setCategoryList] = useState<categoryProps[]>([]);
  const [topNodes, setTopNodes] = useState([]);
  const [nodes, setNodes] = useState<BoardVo[]>([]);
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>();

  const [positionValue, setPositionValue] = useState(``);
  const [zipCodeValue, setZipCodeValue] = useState(``);
  const [addressValue, setAddressValue] = useState(``);
  const [addressDetailValue, setAddressDetailValue] = useState(``);

  const handlePosition = (value) => {
    setPositionValue(value);
  };
  const handleZipCode = (value) => {
    setZipCodeValue(value);
  };
  const handleAddress = (value) => {
    setAddressValue(value);
  };
  const handleAddressDetail = (value) => {
    setAddressDetailValue(value);
  };

  // 카테고리 로드
  const loadCategoryList = useCallback(async () => {
    try {
      const {
        data: publicationCategory,
      } = await PublicationControllerService.getCategoryListUsingGet({
        parentCode: COMMON_CODE_PUBLICATION,
      });
      setCategoryList(
        publicationCategory.map((category: PublicationVo) => ({
          code: category.code,
          codeName: category.codeName,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 최신 자료 로드
  const loadTopDatabase = useCallback(async () => {
    try {
      const {
        data: publicationTop,
      } = await BoardCommonControllerService.top3400UsingGet({
        boardCategoryCode: [BOARD_KIND_PUBLICATION],
        statusCode: '1',
        outDateDispYn: `Y`,
      });
      setTopNodes(publicationTop);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 자료실 목록 로드
  const loadDataBase = useCallback(async () => {
    try {
      const {
        totalCount,
        data,
      } = await BoardCommonControllerService.list400UsingGet({
        boardCategoryCode: [BOARD_KIND_PUBLICATION],
        statusCode: '1',
        outDateDispYn: `Y`,
        page: 1,
        pagePerCount: 4,
      });

      // 페이지네이션 정보
      const dataTotalCount = totalCount as number;
      const pageInfo = getCurrentPageInfo({
        totalItemCount: dataTotalCount,
        baseUri: '/what-we-do/database',
        pageGroupSize: 5,
        currentPage: 1,
        itemsPerPage: 4,
      });
      setCurrentPageInfo(pageInfo);

      // 자료실 목록
      const publication = data as any;
      setNodes(publication);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 동적 데이터 로드
  const loadPage = useCallback(
    async (
      targetPage: number,
      targetKeyword?: string,
      targetCategory?: string,
    ) => {
      const {
        resultCode,
        data: _data,
        totalCount,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_PUBLICATION],
        keyword: targetKeyword,
        category: targetCategory,
        page: targetPage,
        pagePerCount: 4,
      });

      const publicationData = _data as any;

      if (resultCode === `success`) {
        const boardPageInfo = getCurrentPageInfo({
          totalItemCount: totalCount || 0,
          currentPage: targetPage,
          pageGroupSize: 5,
          itemsPerPage: 4,
          baseUri: '/what-we-do/database',
          keyword: targetKeyword,
          category: targetCategory,
        });

        setNodes(publicationData);
        setCurrentPageInfo(boardPageInfo);
      }
    },
    [],
  );

  useEffect(() => {
    // keyword 변경되면 업데이트
    if (params.keyword || params.category) {
      loadPage(searchCurrentPage, params.keyword, params.category);
    } else {
      loadDataBase();
    }
    loadCategoryList();
    loadTopDatabase();
  }, [
    searchCurrentPage,
    loadPage,
    params.keyword,
    params.category,
    loadCategoryList,
    loadTopDatabase,
    loadDataBase,
  ]);

  // 강제 렌더링 실행
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded]);

  const [searchKey, setSearchKey] = useState(``);

  const handleSortKey = (key: string) => {
    setSearchKey(`#${key}`);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="자료실"
      description="for every child, data"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">최신 자료</Tit>
            </h2>
            <p className="header-dec">유니세프 최신 발간물을 확인해 보세요</p>
          </SectionHeader>
          <DatabaseCard
            data={topNodes}
            btn
            handlePosition={handlePosition}
            positionValue={positionValue}
            handleZipCode={handleZipCode}
            zipCodeValue={zipCodeValue}
            handleAddress={handleAddress}
            addressValue={addressValue}
            handleAddressDetail={handleAddressDetail}
            addressDetailValue={addressDetailValue}
          />
        </Container>
      </SectionTop>

      <SectionList
        className="by-sub-main-layout"
        id="tagBox"
        css={`
          ${breakpoint(640)} {
            margin-top: 72px;
          }
        `}
      >
        <Container>
          <Row searchBar justifyContent="flex-end">
            <NewsBoardSearchBar
              searchKey={searchKey}
              initialKeyword={params.keyword}
              onSearch={(value) => {
                navigate(
                  `${currentPageInfo?.baseUri}?${queryString.stringify({
                    keyword: value,
                  })}#tagBox`,
                );
              }}
              // onChange={(e) => onCodeChange(e.target.value)}
              // handleTag={sortTag}
            />
          </Row>
          <TagBox>
            <dt>
              <Tit size="s4-1">원하는 키워드를 선택해주세요.</Tit>
            </dt>
            <dd>
              {loaded && (
                <>
                  <KeywordButton
                    size="sm"
                    color={!params.category ? `blue` : `gray`}
                    outline
                    shape="round"
                    onClick={() => {
                      navigate(`/what-we-do/database#tagBox`);
                      setSearchKey(``);
                    }}
                  >
                    #전체
                  </KeywordButton>
                  {categoryList.map((row) => (
                    <Link
                      to={`/what-we-do/database?category=${row.code}#tagBox`}
                      key={row.code}
                    >
                      <KeywordButton
                        size="sm"
                        color={params.category === row.code ? `blue` : `gray`}
                        outline
                        shape="round"
                        onClick={() => {
                          handleSortKey(row.codeName);
                        }}
                      >
                        #{row.codeName}
                      </KeywordButton>
                    </Link>
                  ))}
                </>
              )}
            </dd>
          </TagBox>
          {params.category === `KW06` ? (
            <TagBox
              css={`
                font-size: 16px;
              `}
            >
              ※모든 아동권리교육 자료의 저작권은 유니세프 한국위원회에 있습니다.
              유니세프 한국위원회의 동의를 얻지 않은 채 무단 전재, 복제 및
              배포하는 것을 금합니다.
              <br />
              비영리, 공익 및 교육 목적으로 자료 사용을 희망하시는 경우 유니세프
              한국위원회 L&D센터(02-724-8551~7/lnd@unicef.or.kr)로 문의주시기
              바랍니다.
            </TagBox>
          ) : (
            ``
          )}
          <DatabaseContainer>
            {nodes.map((item) => (
              <DatabaseItem
                basePath={currentPageInfo?.baseUri}
                queryString={location.search}
                publication={item}
                categoryList={categoryList}
                handleSortKey={handleSortKey}
                key={item.boardIndexNumber}
                handlePosition={handlePosition}
                positionValue={positionValue}
                handleZipCode={handleZipCode}
                zipCodeValue={zipCodeValue}
                handleAddress={handleAddress}
                addressValue={addressValue}
                handleAddressDetail={handleAddressDetail}
                addressDetailValue={addressDetailValue}
              />
            ))}
          </DatabaseContainer>
          <Row
            justifyContent="center"
            css={`
              margin-top: 64px;
            `}
          >
            <TagPagination {...currentPageInfo} anchor="#tagBox" />
          </Row>
        </Container>
      </SectionList>
    </LayoutWithTitle>
  );
};

export default Database;
